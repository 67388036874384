import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  outer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    margin: '-80px auto auto auto',
    padding: '80px 0px',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '40px 0px',
      '& form': {
        '& h4': {
          textAlign: 'center',
          padding: '0px 10px',
        },
        '&>div>div': {
          width: '100%',
        },
      },
    },
  },
  contactColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '0',
      paddingBottom: '40px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '48%',
      paddingLeft: '10%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: '5%',
    },
  },
}))

const ContactUsBodyModule = (props) => {
  const classes = useStyles()

  return (
    <SbEditable content={props.blok}>
      <Container fixed>
        <Box className={classes.outer}>
          <Box className={classes.contactColumn}>
            {renderBloks(props.blok.formModule)}
          </Box>
          <Box className={classes.contactColumn}>
            {renderBloks(props.blok.contactUsModule)}
          </Box>
        </Box>
      </Container>
    </SbEditable>
  )
}

export default ContactUsBodyModule
